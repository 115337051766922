import request from '@/utils/request'

/**
 * 获取全部产品属性
 */
export const getProductAttrList = params => {
  return request({
    url: '/product/attr',
    method: 'GET',
    params
  })
}

/**
 * 获取全部产品属性
 */
export const getProductAttrById = id => {
  return request({
    url: `/product/attr/${id}`,
    method: 'GET'
  })
}

/**
 * 添加产品属性
 */
export const addProductAttr = data => {
  return request({
    url: '/product/attr',
    method: 'POST',
    data
  })
}

/**
 * 修改产品属性
 */
export const updateProductAttr = data => {
  return request({
    url: '/product/attr',
    method: 'PUT',
    data
  })
}

/**
 * 删除指定id产品属性
 * @param id 产品属性id
 * @returns {*}
 */
export const deleteProductAttrById = id => {
  return request({
    url: `/product/attr/${id}`,
    method: 'DELETE'
  })
}
