<template>
  <el-dialog
    :model-value="modelValue"
    width="500px"
    title="添加商品属性"
    @close="closed"
  >
    <el-form ref="formRef" :model="form" :label-width="100">
      <el-form-item label="上级属性">
        <el-select
          v-model="form.parentId"
          placeholder="请选择"
          style="width: 100%"
        >
          <el-option
            v-for="item in productAttrTopData"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="属性名称" prop="name">
        <el-input v-model="form.name" placeholder="请输入属性名称"></el-input>
      </el-form-item>
      <el-form-item label="属性类型" prop="attrType">
        <el-radio v-model="form.attrType" :label="0">单选</el-radio>
        <el-radio v-model="form.attrType" :label="1">多选</el-radio>
      </el-form-item>
      <el-form-item label="排序号" prop="sortNumber">
        <el-input-number v-model="form.sortNumber" controls-position="right" />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="closed">取消</el-button>
        <el-button type="primary" @click="onSubmitClick">保存</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { defineEmits, defineProps, ref } from 'vue'
import { ElMessage } from 'element-plus'
import { addProductAttr, getProductAttrList } from '@/api/product-attr'

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true
  },
  parentId: {
    type: Number,
    required: true
  }
})

const emits = defineEmits(['update:modelValue', 'parentId', 'close'])

const formRef = ref(null)
const form = ref({
  parentId: props.parentId,
  name: '',
  attrType: 0,
  sortNumber: 1
})

const productAttrTopData = ref([{ id: 0, name: '顶级属性' }])
const getProductAttrListData = async () => {
  const result = await getProductAttrList()
  for (const attr of result) {
    if (attr.parentId === 0) {
      productAttrTopData.value.push(attr)
    }
  }
}
getProductAttrListData()

const closed = () => {
  emits('update:modelValue', false)
  emits('close')
  formRef.value.resetFields()
}

const loading = ref(false)

const onSubmitClick = async () => {
  loading.value = true
  await addProductAttr(form.value)
  ElMessage.success('添加成功')
  loading.value = false
  closed()
}
</script>

<style lang="scss" scoped></style>
