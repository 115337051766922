<template>
  <div>
    <el-card>
      <div
        class="tool"
        style="background: #fafafa;display: flex;padding: 10px;border: 1px solid #ebeef5;border-bottom: 0;"
      >
        <div>
          <el-button size="small" type="primary" @click="addClick(0)" v-permission="['sys:productAttr:add']"
            >添加
          </el-button>
          <el-button size="small" @click="actionUnfoldFunc(true)"
            >全部展开</el-button
          >
          <el-button size="small" @click="actionUnfoldFunc(false)"
            >全部折叠</el-button
          >
        </div>
        <div style="flex: 1;text-align: right;">
          <el-button size="small" type="primary" @click="getListData" plain
            >刷新列表
          </el-button>
        </div>
      </div>
      <div class="table">
        <el-table
          ref="tableRef"
          border
          v-loading="loading"
          :header-cell-style="{
            background: '#fafafa',
            color: '#333333',
            padding: '4px 0'
          }"
          :cell-style="{ padding: '4px 0' }"
          :data="tableData"
          style="width: 100%"
          row-key="id"
        >
          <el-table-column type="selection" width="55" />
          <el-table-column property="name" label="属性名称" />
          <el-table-column property="attrType" label="类型" width="100">
            <template #default="scope">
              <el-tag v-if="scope.row.attrType == 0">单选</el-tag>
              <el-tag type="warning" v-if="scope.row.attrType == 1"
                >多选</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column property="sortNumber" label="排序" width="55" />
          <el-table-column property="createTime" label="创建时间" width="160" />
          <el-table-column fixed="right" label="操作" width="150">
            <template #default="scope">
              <el-button
                type="text"
                size="small"
                @click="addClick(scope.row.id)"
                v-if="scope.row.parentId === 0"
                v-permission="['sys:productAttr:add']"
                >添加
              </el-button>
              <el-button
                type="text"
                size="small"
                @click="updateClick(scope.row)"
                v-permission="['sys:productAttr:update']"
                >修改
              </el-button>
              <el-button
                type="text"
                size="small"
                style="color: #ff4d4f"
                @click="deleteClick(scope.row)"
                v-permission="['sys:productAttr:delete']"
                >删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>
    <add-product-attr
      v-if="addDialogVisible"
      v-model="addDialogVisible"
      :parentId="addParentId"
      @close="getListData"
    ></add-product-attr>
    <edit-product-attr
      v-if="editDialogVisible"
      v-model="editDialogVisible"
      :id="selectItemId"
      @close="getListData"
    ></edit-product-attr>
  </div>
</template>

<script setup>
import { ref, nextTick } from 'vue'
import { createTree } from '@/utils/tree'
import { getProductAttrList, deleteProductAttrById } from '@/api/product-attr'
import AddProductAttr from './components/AddProductAttr'
import EditProductAttr from './components/EditProductAttr'
import { ElMessage, ElMessageBox } from 'element-plus'

/**
 * 表格数据相关
 */

const loading = ref(false)
const tableData = ref([])
// 获取数据的方法
const getListData = async () => {
  loading.value = true
  const result = await getProductAttrList()
  tableData.value = createTree(result)
  loading.value = false
}
getListData()

/**
 * 选择要操作的列表id
 */
const selectItemId = ref(0)

/**
 * 折叠表格
 */
const isExpand = ref(null)
const tableRef = ref(null)
const actionUnfoldFunc = isAss => {
  isExpand.value = isAss
  // this.tableData为返回的二级数据data
  nextTick(() => {
    forTableArr(tableData.value, isExpand.value)
  })
}
const forTableArr = (arr, isExpand) => {
  arr.forEach(i => {
    tableRef.value.toggleRowExpansion(i, isExpand)
    if (i.children) {
      forTableArr(i.children, isExpand)
    }
  })
}
/**
 * 显示添加窗口
 */
const addDialogVisible = ref(false)
const addParentId = ref(0)
const addClick = parentId => {
  addDialogVisible.value = true
  addParentId.value = parentId
}

const editDialogVisible = ref(false)
const updateClick = row => {
  selectItemId.value = row.id
  editDialogVisible.value = true
}

/**
 * 删除按钮点击事件
 * @param row
 */
const deleteClick = row => {
  ElMessageBox.confirm('确认删除[' + row.name + ']属性吗？', '删除', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    type: 'warning'
  })
    .then(async () => {
      await deleteProductAttrById(row.id)
      ElMessage({
        type: 'success',
        message: '删除成功'
      })
      getListData()
    })
    .catch(() => {})
}
</script>

<style scoped></style>
